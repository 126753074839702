import { Header, ProfileForm } from 'components';
import { useApp } from 'hooks';
import { omit } from 'lodash';
import { Container, Card } from 'ui';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { Routes, Route, NavLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Invoices from './Invoices';
import Api from './Api';
import usePostHog from 'hooks/usePosthog';

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        lastName
        firstName
        company
        phone
        avatarUrl
      }
    }
  }
`;

const Account = () => {
  usePostHog();
  const { t } = useTranslation('account');
  const { notify, user } = useApp();
  const [updateUser] = useMutation(UPDATE_USER);

  const handleSubmit = async values => {
    let payload =
      typeof values.avatar === 'string' ? omit(values, 'avatar') : values;
    try {
      await updateUser({
        variables: {
          input: {
            userId: user.id,
            ...payload,
          },
        },
      });
      notify(t('auth:profile_update_success'));
    } catch (err) {
      console.dir(err);
    }
  };

  return (
    <>
      <Header
        tabs={
          <Tabs hasNoPadding>
            <Tab>
              <TabNavButton to="" as={NavLink}>
                {t('my_account')}
              </TabNavButton>
            </Tab>
            {/* <Tab>
              <TabNavButton to="security" as={NavLink}>
                {t('security')}
              </TabNavButton>
            </Tab> */}
            <Tab>
              <TabNavButton to="invoices" as={NavLink}>
                {t('invoices')}
              </TabNavButton>
            </Tab>
            {user?.canAccessWrapperApi?.value && (
              <Tab>
                <TabNavButton to="api" as={NavLink}>
                  {t('api')}
                </TabNavButton>
              </Tab>
            )}
          </Tabs>
        }
      />

      <Routes>
        {/* <Route path="security" element={<Security />} /> */}
        <Route path="invoices" element={Boolean(user) ? <Invoices /> : null} />
        {user?.canAccessWrapperApi?.value && (
          <Route path="api" element={<Api />} />
        )}
        <Route
          element={
            <Container size="narrow" variant="main">
              <Card>
                <ProfileForm user={user} onSubmit={handleSubmit} />
              </Card>
              {/* <Card title="Suppression de compte">
                  <SubText>{t('delete_explanation')}</SubText>
                  <Buttons>
                    <Button variant="outline">{t('delete_account')}</Button>
                  </Buttons>
                </Card> */}
            </Container>
          }
        />
      </Routes>
    </>
  );
};

export default Account;
