import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import {
  POSTHOG_ENABLED,
  POSTHOG_API_KEY,
  POSTHOG_HOST,
  POSTHOG_APP,
  POSTHOG_ENVIRONMENT,
} from '../constants';

export default function usePostHog() {
  useEffect(() => {
    if (POSTHOG_ENABLED) {
      posthog.init(POSTHOG_API_KEY, {
        api_host: POSTHOG_HOST,
        persistence: 'localStorage',
        capture_pageview: false,
        loaded: posthog => {
          posthog.register({
            // add extra tags for filtering
            app: POSTHOG_APP,
            environment: POSTHOG_ENVIRONMENT,
          });
        },
      });
    }
  }, []);

  let location = useLocation();
  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);
}
