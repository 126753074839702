import { useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Redirect, Route, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { media } from '@tymate/margaret';
import { Sidebar } from 'components';
import { useRoutes, useApp } from 'hooks';
import Dashboard from './Dashboard';
import Requests from './Requests';
import Activities from './Activities';
import Vault from './Vault';
import Users from './Users';
import EditWork from './EditWork';
import usePostHog from 'hooks/usePosthog';

const Wrapper = styled.div`
  padding-bottom: 49px;

  ${media.tablet`
    padding-left: 72px;
    padding-bottom: 0;
  `}
`;

const Content = styled.div``;

const GET_WORK_NAME = gql`
  query getWorkName($slug: String!) {
    work(slug: $slug) {
      id
      slug
      name
      canUpdate {
        value
      }
      canCreateRequest {
        value
        reasons {
          details
          fullMessages
        }
        message
      }
      canAccessVault {
        value
      }
      tenant {
        owner {
          id
        }
      }
    }
  }
`;

const Work = () => {
  const { organizationSlug, workSlug } = useParams();
  usePostHog();
  const { user } = useApp();
  const { addRouteName } = useRoutes();
  const { data: workData } = useQuery(GET_WORK_NAME, {
    variables: { slug: workSlug },
  });
  const workName = workData?.work?.name;

  const tenantOwnerId = workData?.work?.tenant?.owner?.id;

  const isTenantOwner = Boolean(user?.id) && tenantOwnerId === user?.id;

  useEffect(() => {
    addRouteName({
      path: `/organizations/${organizationSlug}/${workSlug}`,
      name: workName,
    });
  }, [addRouteName, organizationSlug, workName, workSlug]);

  return (
    <Wrapper>
      <Sidebar
        shouldShowUsers={workData?.work?.canUpdate?.value}
        shouldShowVault={workData?.work?.canAccessVault?.value}
        shouldShowNewRequest={workData?.work?.canCreateRequest?.value}
        isTenantOwner={isTenantOwner}
      />

      <Content>
        <Routes>
          <Route
            path=""
            element={<Dashboard isTenantOwner={isTenantOwner} />}
          />
          <Route
            path="requests/*"
            element={<Requests isTenantOwner={isTenantOwner} />}
          />
          <Route path="activities" element={<Activities />} />
          {workData?.work?.canAccessVault?.value && (
            <Route path="vault/*" element={<Vault />} />
          )}
          <Route path="users/*" element={<Users />} />
          <Route path="settings" element={<EditWork />} />
          <Redirect to="" />
        </Routes>
      </Content>
    </Wrapper>
  );
};

export default Work;
