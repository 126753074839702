import 'react-app-polyfill/ie11';
import 'core-js/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { MargaretProvider } from '@tymate/margaret';
import AuthenticationProvider from 'containers/AuthenticationProvider';
import GraphQLProvider from 'containers/GraphQLProvider';
import AppProvider from 'containers/AppProvider';
import { theme, fontStyles } from 'ui';
import App from './App';
import Login from 'containers/Auth/Login';
import External from 'containers/External';
import ExternalDocument from 'containers/ExternalDocument';
import RoutesProvider from 'containers/RoutesProvider';
import Callback from 'containers/Auth/Callback';
import * as serviceWorker from './serviceWorker';
import './i18n';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'typeface-montserrat';
import 'react-phone-number-input/style.css';
import 'flag-icons/css/flag-icons.css';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import {
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE,
} from './constants';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

const GlobalStyles = createGlobalStyle`
  body {
    ${fontStyles.body}
  }

  h1 {
    ${fontStyles.h1}
  }

  h2 {
    ${fontStyles.h2}
  }

  h3 {
    ${fontStyles.h3}
  }
`;

// Not supported in older browsers, so gotta use a polyfill
window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: SENTRY_ENVIRONMENT,
  release: SENTRY_RELEASE,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
});

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div />}>
      <MargaretProvider theme={theme}>
        <AuthenticationProvider>
          <BrowserRouter>
            <GraphQLProvider>
              <AppProvider>
                <RoutesProvider>
                  <GlobalStyles />
                  <Routes>
                    {/* <Route path="login" element={<Login />} /> */}
                    <Route path="login" element={<Login />} />
                    <Route
                      path="requests/:tenantApiId/:apiId/documents/:documentApiId"
                      element={<ExternalDocument />}
                    />
                    <Route
                      path="requests/:tenantApiId/:apiId"
                      element={<External />}
                    />
                    <Route path="callback/login" element={<Callback />} />
                    <Route path="*" element={<App />} />
                  </Routes>
                </RoutesProvider>
              </AppProvider>
            </GraphQLProvider>
          </BrowserRouter>
        </AuthenticationProvider>
      </MargaretProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
